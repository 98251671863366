@use 'sass:map';

$nude: #fce6d8;
$white: #ffffff;
$tan: #b77a46;
$black: #1a1a1a;
$denim: #204b78;
$dark-primary-text: #212121;
$dark-secondary-text: #757575;
$dark-disabled-text: #9e9e9e;
$dark-dividers: #e8e8e8;
$dark-focused: #e0e0e0;
$dark-faded: #f2f2f2;
$light-primary-text: white;
$light-secondary-text: #b3b3b3;
$light-disabled-text: #808080;
$light-dividers: #1f1f1f;
$light-focused: #1f1f1f;

$grey-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

$black-palette: (
  50: #f9f9f9,
  100: #f2f2f2,
  200: #e8e8e8,
  300: #d8d8d8,
  400: #b4b4b4,
  500: #949494,
  600: #6c6c6c,
  700: #595959,
  800: #3a3a3a,
  900: #1a1a1a,
  contrast: (
    contrast: (
      50: $black,
      100: $black,
      200: $black,
      300: $white,
      400: $white,
      500: $white,
      600: $white,
      700: $white,
      800: $white,
      900: $white,
      A100: $black,
      A200: $white,
      A400: $white,
      A700: $white,
    ),
  ),
);

// Background palette for dark themes.
$dark-theme-background-palette: (
  status-bar: black,
  app-bar: map.get($grey-palette, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  // TODO(kara): check style with Material Design UX
  card: map.get($grey-palette, 800),
  dialog: map.get($grey-palette, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map.get($grey-palette, 800),
  focused-button: $light-focused,
  selected-button: map.get($grey-palette, 900),
  selected-disabled-button: map.get($grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: map.get($grey-palette, 700),
  disabled-list-option: black,
  tooltip: map.get($grey-palette, 700),
);

// Foreground palette for light themes.
$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$dark-theme-foreground-palette: (
  base: white,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);
