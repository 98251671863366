$medias: (
  'mobile': 320px,
  'web': 450px,
) !default;

@mixin breakpoint($media) {
  @if map-has-key($medias, $media) {
    @media (min-width: map-get($medias, $media)) {
      @content;
    }
  } @else {
    @warn "Available medias are: #{map-keys($medias)}.";
  }
}
