/* You can add global styles to this file, and also import other style files */
@import 'app/shared/styles/breakpoint.mixin.scss';
@import 'app/shared/styles/colors.scss';
html,
body {
  height: 100%;
  background-color: $white;
  margin: 0;
  font-family: Non-SeasonalGeo-Medium, Arial !important;
  @include breakpoint('mobile') {
    overflow-x: hidden;
  }
  @include breakpoint('web') {
    overflow-x: auto;
  }
}
body {
  @include breakpoint('mobile') {
    position: relative;
  }
  @include breakpoint('web') {
    position: static;
  }
}

div,
span,
table {
  font-family: Non-SeasonalGeo-Medium, Arial !important;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.logo-pulse {
  margin-top: 20px;
  content: url('/assets/images/aritzia_logo.svg');
  animation: pulse 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate
    both;
}

.error-message {
  background: #323232;
  color: #c2c2c2;
}

@keyframes pulse {
  to {
    transform: scale(1.1);
  }
}
